const areArraysEquals = (arr1, arr2, isObjectArr = false) =>
    Array.isArray(arr1) &&
    Array.isArray(arr2) &&
    arr1.length === arr2.length &&
    arr1.every((val, index) =>
        isObjectArr
            ? JSON.stringify(val) === JSON.stringify(arr2[index])
            : val === arr2[index]
    );

// * To remove duplicates from array of objects.
const removeDuplicatesFromArrayOfObjects = (arrayOfObjects = []) =>
    Array.from(new Set(arrayOfObjects.map(JSON.stringify))).map(JSON.parse);

export { areArraysEquals, removeDuplicatesFromArrayOfObjects };
