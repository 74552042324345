import {
    fetchGet,
    fetchPost,
    jsonToQueryParam,
    fetchDelete,
} from "./CommonServiceUtils";
import Constants from "../Constants";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = `${Constants.BASE_URL}coreservice/`;

const getContactAttributes = (regionId, skip = 0, limit = 1000) => {
    return fetchGet(
        `${BASE_URL}attributes?${jsonToQueryParam({ regionId, skip, limit })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.ATTRIBUTE,
            AccessPermissionModules[AccessPermissionModuleNames.ATTRIBUTE]
                .actions.ListAttributes
        )
    );
};

const getSegmentCategories = (regionId, skip = 0, limit = 1000) => {
    return fetchGet(
        `${BASE_URL}segmentcategories?${jsonToQueryParam({
            regionId,
            limit,
            skip,
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SEGMENT,
            AccessPermissionModules[AccessPermissionModuleNames.SEGMENT].actions
                .ListSegmentCaregories
        )
    );
};

const createSegmentCategory = ({ name, regionId, iconUrl }) => {
    return fetchPost(
        `${BASE_URL}segmentcategories`,
        { name, regionId, iconUrl },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SEGMENT,
            AccessPermissionModules[AccessPermissionModuleNames.SEGMENT].actions
                .CreateSegmentCategory
        )
    );
};

const getSegments = (regionId, limit = 1000, skip = 0) => {
    return fetchGet(
        `${BASE_URL}segments?${jsonToQueryParam({ regionId, skip, limit })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SEGMENT,
            AccessPermissionModules[AccessPermissionModuleNames.SEGMENT].actions
                .ListSegments
        )
    );
};

const createSegments = ({ name, regionId, categoryId, filter }) => {
    return fetchPost(
        `${BASE_URL}segments`,
        { name, regionId, categoryId, filter },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SEGMENT,
            AccessPermissionModules[AccessPermissionModuleNames.SEGMENT].actions
                .CreateSegment
        )
    );
};

const getActivities = (activityFilters) => {
    return fetchGet(
        `${BASE_URL}activities?${jsonToQueryParam(activityFilters)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.ACTIVITY,
            AccessPermissionModules[AccessPermissionModuleNames.ACTIVITY]
                .actions.ListActivities
        )
    );
};

const getEvents = (queryObj) => {
    return fetchGet(
        `${BASE_URL}events?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.EVENT,
            AccessPermissionModules[AccessPermissionModuleNames.EVENT].actions
                .ListEvents
        )
    );
};

const deleteSegment = (id) => {
    return fetchDelete(
        `${BASE_URL}segments/${id}`,
        null,
        null,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.SEGMENT,
            AccessPermissionModules[AccessPermissionModuleNames.SEGMENT].actions
                .DeleteSegment
        )
    );
};

// * Retrieve all the segments by applying limit, skip and other args automatically.
const getAllSegments = async (regionId) => {
    const limit = 500;
    let totalCount = 0,
        segments = [];

    try {
        do {
            const segmentsResponse = await getSegments(
                regionId,
                limit,
                segments.length
            );
            totalCount = segmentsResponse.total;
            segments = [...segments, ...segmentsResponse.items];
        } while (segments.length < totalCount);
        return segments;
    } catch (e) {
        return Promise.reject(e);
    }
};

export {
    getContactAttributes,
    getSegmentCategories,
    getSegments,
    getActivities,
    createSegments,
    getEvents,
    deleteSegment,
    createSegmentCategory,
    getAllSegments,
};
